import React from "react";
import StickyTitle from "./sticky-title";
import BlockText from "./block-text";
import MixMediaGrid from "./mix-media-grid";

import * as styles from "./about-section.module.css";
import VimeoPlayer from './vimeo-player';

const AboutSection = props => {
  const { data, slug } = props;

  const content = (data._rawContent || [])
    .map(c => {
      let el = null;
      switch (c._type) {
        case "videoPlug":
          el = <VimeoPlayer key={c._key} url={c.url} />;
          break;
        case "mixMediaGrid":
          el = <MixMediaGrid key={c._key} {...c} />;
          break;
        case "textPlug":
          el = (
            <div className={styles.text}>
              <BlockText key={c._key} blocks={c.text} />
            </div>
          );
          break;
        default:
          el = null;
      }
      return el;
    });

  return (
    <section id={slug} className={styles.root}>
      <StickyTitle title={data.title} />
      {content}
    </section>
  );
};


export default AboutSection;
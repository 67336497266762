import React from "react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";

import clientConfig from "../../client-config";
import BlockText from "./block-text";

import * as styles from "./mix-media-grid.module.css";

const getImage = image => {
  let img = null;
  if (image && image.asset) {
    const imageData = getGatsbyImageData(
      image,
      { maxWidth: 1400 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        className={styles.image}
        image={imageData}
        alt={image.alt}
      />
    );
  }
  return img;
};

const MixMediaGrid = ({ items, credits }) => {
  return (
    <div className={styles.root}>
      {items.map(({ title, description, image, _key }) => (
        <div key={_key} className={styles.gridItem}>
          {getImage(image)}
          <div className={styles.text}>
            <h4>{title}</h4>
            <BlockText blocks={description} />
          </div>
        </div>
      ))}

      {items.length%2 != 0 ? <div className={styles.gridItem}></div> : ''}

      {credits && (
        <div className={styles.gridItem}>
          <BlockText blocks={credits} />
        </div>
      )}
    </div>
  )
};

export default MixMediaGrid;
import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import { useInView } from 'react-intersection-observer';

import * as styles from './vimeo-player.module.css';

const VimeoPlayer = ({ url }) => {

  if (!url) {
    return null
  }

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  return (
    <div className={styles.root} ref={ref}>
      <ReactPlayer 
        className={styles.player}
        url={url}
        loop={true}
        playing={inView}
        width="100%"
        height="100%"
        controls={true}
      />
    </div>
  );
};

export default VimeoPlayer;
import React, { useContext } from "react";
import { graphql } from "gatsby";

import AboutSection from "../components/about-section";
import BlockText from "../components/block-text";
import Layout from "../containers/layout";
import ThemeContext from "../context/ThemeContext";
import { ScrollProvider } from "../context/ScrollContext";
import { cn } from "../lib/helpers";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";

import * as styles from "./about.module.css";

export const query = graphql`
  query AboutPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    contact: sanityContact {
      id
      _rawText
    }
    team: sanityTeam {
      id
      _rawText
    }
    sections: allSanityAboutSection(sort: {fields: publishedAt, order: DESC}) {
      nodes {
        _key
        slug {
          current
        }
        title
        _rawContent(resolveReferences: {maxDepth: 10})
      }
    }
  }
`;

const AboutPage = props => {
  const { data, errors } = props;
  const theme = useContext(ThemeContext);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const sections = data.sections.nodes;
  const contact = data.contact;
  const team = data.team;

  return (
    <Layout>
      <ScrollProvider>
        <SEO title={site.title} description={site.description} keywords={site.keywords} />
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>About</h1>
        </div>
        <div className={cn(styles.sections, theme.dark ? styles.dark : '')}>
          {sections && sections.map(section => <AboutSection key={section._key} data={section} slug={section.slug.current} />)}

          <section className={styles.details}>
            <div>
              <div className={styles.detailTitle}>
                <h3>Contact</h3>
              </div>
              <div className={styles.detailText}>
                <BlockText blocks={contact._rawText} />
              </div>
            </div>
            <div>
              <div className={styles.detailTitle}>
                <h3>Team</h3>
              </div>
              <div className={styles.detailText}>
                <BlockText blocks={team._rawText} />
              </div>
            </div>
          </section>
        </div>
      </ScrollProvider>
    </Layout>
  );
};

export default AboutPage;
